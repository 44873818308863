<template>
  <div class="row">
    <!-- {{ this.$store.state.profile.user.jabatan1 }} -->
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>

        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Daftar Surat Masuk</b>
            </div>
            <br/>
            <div class="col-md-2 col-12" v-if="canSeeAll">
              <select
                class="pull-right form-control"
                v-model="from"
                @change="getFrom"
              >
                <option value="all">Satu Unit</option>
                <option value="pimpinan">Atensi Pimpinan</option>
                <option value="belum_dispo">Belum Didisposisi</option>
                <option value="sudah_dispo">Sudah Didisposisi</option>
                <option value="pencarian_khusus">Pencarian Khusus</option>
              </select>
            </div>
            <div
              style="width: 800px; margin: 0 auto"
              v-if="showFilter == false"
            >
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <form method="post" @submit.prevent="changeDt">
                      <div class="form-body">
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-10">
                            <div class="row">
                              <div class="form-group" style="width: 320px">
                                <input
                                  placeholder="Pencarian Nomor Surat"
                                  type="text"
                                  class="form-control"
                                  v-model="nomor_surat"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-10">
                            <div class="row">
                              <div class="form-group" style="width: 320px">
                                <input
                                  placeholder="Pencarian Nomor Kendali"
                                  type="text"
                                  class="form-control"
                                  v-model="nomor_kendali"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-10">
                            <div class="row">
                              <div class="form-group" style="width: 320px">
                                <input
                                  placeholder="Pencarian Isi Ringkas"
                                  type="text"
                                  class="form-control"
                                  v-model="ringkasan"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-10">
                            <div class="row">
                              <div class="form-group" style="width: 320px">
                                <input
                                  placeholder="Pencarian Asal Surat"
                                  type="text"
                                  class="form-control"
                                  v-model="asal"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                           <div class="col-md-9 col-sm-10">
                            <div class="row">
                              <div class="form-group" style="width: 320px">
                                <input 
                                  placeholder="Pencarian OPD"
                                  type="text"
                                  class="form-control"
                                  v-model="opd"
                                />
                              </div>
                            </div>
                           </div>
                        </div>
                      </div>
                      <div class="form-actions">
                        <div class="row justify-content-md-center">
                          <div class="col-md-7 pl-5">
                            <button
                              type="submit"
                              class="btn btn-success"
                              title="Simpan"
                            >
                              Submit</button
                            >&nbsp;
                            <button
                              type="button"
                              @click="resetInputs()"
                              class="btn btn-inverse"
                              title="Reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div>{{ showFilter }}</div> -->
            <div
              :class="[
                'col-md-12 p-5 collapse card-filter-gt ',
                { in: showFilter },
              ]"
              transition="collapsing"
            >
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <form method="post" @submit.prevent="changeDt">
                      <div class="form-body">
                        <div class="row justify-content-md-center">
                          <label
                            class="text-right mt-2 col-md-3 col-sm-12"
                            style="font-size: 15px; font-weight: 700"
                            >Nomor / Perihal Surat / Isi Ringkas</label
                          >
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="cari"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <label
                            class="text-right mt-2 col-md-3 col-sm-12"
                            style="font-size: 15px; font-weight: 700"
                            >Asal Surat</label
                          >
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="asal"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <label
                            class="text-right mt-2 col-md-3 col-sm-12"
                            style="font-size: 15px; font-weight: 700"
                            >Periode Surat</label
                          >
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 160px">
                                <input
                                  type="date"
                                  class="form-control"
                                  data-date=""
                                  data-date-format="YYYY-MM-DD"
                                  v-model="start_date"
                                />
                              </div>
                              <label
                                class="
                                  control-label
                                  text-right
                                  mt-2
                                  col-md-auto
                                "
                                >s/d</label
                              >
                              <div class="form-group" style="width: 160px">
                                <input
                                  type="date"
                                  class="form-control"
                                  data-date=""
                                  data-date-format="YYYY-MM-DD"
                                  v-model="end_date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-actions">
                        <div class="row justify-content-md-center">
                          <div class="col-md-7 pl-5">
                            <button
                              type="submit"
                              class="btn btn-success"
                              title="Simpan"
                            >
                              Submit</button
                            >&nbsp;
                            <button
                              type="button"
                              @click="resetInput()"
                              class="btn btn-inverse"
                              title="Reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </h3>

        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :dashboard="dashboard"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onAtasan="doAtasan"
              :onEdit="doEdit"
              :onDetailTL="doDetailTL"
              :onDetailTLI="doDetailTLI"
              @onDelete="doDelete"
              :overideSearch="search"
              @onSearch="onSearch"
              :forbidEdit="forbidUpdate"
              :forbidAtasan="forbidAtasan"
              :forbidDetail="forbidRead"
              :forbidDetailTL="forbidRead"
              :forbidKonfirmasi="forbidKonfirmasi"
              :forbidDelete="true"
            />
          </div>
        </div>
        <hr />
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Treeselect from '../../../components/helpers/treeselect.vue';
import Input from '../../../components/helpers/input.vue';

export default {
  components: {
    RotateSquare5,
    Multiselect,
    Table,
    Treeselect,
    Input,
    
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");

    let roles = this.$store.state.profile.user.datarole1;

    let params = {};
    if (last[0].includes("document_in")) {
      params = JSON.parse(last[1]);
    }
    if (params.approval != undefined) {
      params = {};
    }

    let cari = this.from;
    let asal = this.form;

    let filter = false;
    if (cari == "Pencarian Khusus") {
      filter = true;
      alert(filter);
    } else if (asal == "Pencarian Khusus") {
      filter = true;
      alert(filter);
    }

    return {
      from: roles,
      search: params.search || "",
      opd: params.opd || null,
      keterangan: params.keterangan || null,
      // showFilter: false,
      showFilter: filter,
      cari: "",
      asal: "",
      // nomor_agenda: "",
      // periode_surat: "",
      // perihal: "",
      // nomor_surat: "",
      start_date: "",
      end_date: "",
      options: [],
      optionsOPD: [],
      payload: {
        opd: null,
      },
      headers: {
        description: {
          label: "Isi Ringkas",
          sortable: true,
        },
        no_opd: {
          label: "Nomor Kendali",
          sortable: true,
        },
        nomor_surat: {
          label: "Nomor Surat",
          sortable: true,
        },
        asal_surat: {
          label: "Asal Surat",
          sortable: true,
        },
        tanggal_diterima: {
          label: "Tanggal Diteruskan",
          sortable: true,
          type: "date",
        },
        statustindaklanjut: {
          label: "Status",
        },
        penerima: {
          label: "Tujuan Disposisi",
        },
        instruksi: {
          label: "Instruksi",
        },
        // no_urut: {
        //   label: "Hasil Tindaklanjut",
        // },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.documentIn.items !== undefined) {
        if (this.$store.state.documentIn.items.items !== undefined) {
          if (
            !Array.isArray(this.$store.state.documentIn.items.items) &&
            this.$store.state.documentIn.items.items !== undefined
          ) {
            data = Object.keys(this.$store.state.documentIn.items.items).map(
              (i) => this.$store.state.documentIn.items.items[i]
            );
          } else {
            data = this.$store.state.documentIn.items.items;
          }
        }
      }
      return data;
    },
    permissions() {
      if (this.$store.state.profile === undefined) {
        return {
          disposition_in: {},
          document_in: {},
          document_out: {},
        };
      } else if (this.$store.state.profile.permissions === undefined) {
        return {
          disposition_in: {},
          document_in: {},
          document_out: {},
        };
      }
      return this.$store.state.profile.permissions;
    },
    filters() {
      let filt = {};
      if (this.$store.state.documentIn.items !== undefined) {
        if (this.$store.state.documentIn.items.filters !== undefined) {
          filt = this.$store.state.documentIn.items.filters;
        }
      }
      return filt;
    },
    dashboard() {
      let dash = {};
      if (this.$store.state.documentIn.items !== undefined) {
        if (this.$store.state.documentIn.items.dashboard !== undefined) {
          dash = this.$store.state.documentIn.items.dashboard;
        }
      }
      return dash;
    },
    state() {
      return this.$store.state.documentIn;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.update;
      }
      return false;
    },
    canSeeAll() {
      if (this.$store.state.profile.permissions.document_in_all) {
        return this.$store.state.profile.permissions.document_in_all.read;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.read;
      }
      return false;
    },
    forbidAtasan() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.read;
      }
      return false;
    },
    forbidKonfirmasi() {
      if (this.$store.state.profile.permissions.document_in_confirm) {
        return this.$store.state.profile.permissions.document_in_confirm.create;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.delete;
      }
      return false;
    },
    forbidDisposisi() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return this.$store.state.profile.permissions.disposition_in.create;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      search: this.search,
      from: this.from,
      jabatan: this.$store.state.profile.user.structural_position_id,
      // jabatan: this.$store.state.profile.user.jabatan1,
      // jabatan: this.$store.state.documentIn.items.atensi,
      start_date: this.start_date,
      end_date: this.end_date,
    };
    this.treeUnit();
    this.$store.commit("documentIn/STATE", state);
    this.get(state);
  },
  methods: {
    onSearch(val) {
      this.search = val;
    },
    goBack() {
      this.$router.push("/dispositionIn");
    },
    treeUnit() {
      axios.get(`/kode_klasifikasi/list_indeks`).then((res) => {
        this.options = res.data.data;
      });
    },
    handleShowFilter() {
      this.showFilter = !this.showFilter;
    },
    changeDt() {
      // this.$children[0].$refs.table.reload();
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        nomor_surat: this.nomor_surat,
        nomor_kendali: this.nomor_kendali,
        ringkasan: this.ringkasan,
        opd: this.opd,
        cari: this.cari,
        asal: this.asal,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("documentIn/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("documentIn/getDocumentIn", val);
    },
    resetInputs() {
      this.cari = "";
      this.nomor_surat = "";
      this.nomor_kendali = "";
      this.ringkasan = "";
      this.opd = "";
      this.asal = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        cari: this.cari,
        asal: this.asal,
      };
      this.$store.commit("documentIn/STATE", state);
      this.get(state);
      // this.$children[0].$refs.table.reload();
    },
    resetInput() {
      // this.start_date = moment().startOf("year").format("YYYY-MM-DD");
      // this.end_date = moment().endOf("year").format("YYYY-MM-DD");
      this.start_date = "";
      this.end_date = "";
      this.cari = "";
      this.asal = "";
      this.nomor_surat = "";
      this.nomor_kendali = "";
      this.ringkasan = "";
      this.opd = "";
      // this.nomor_agenda = "";
      // this.periode_surat = "";
      // this.perihal = "";
      // this.nomor_surat = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        cari: this.cari,
        nomor_surat: this.nomor_surat,
        nomor_kendali: this.nomor_kendali,
        ringkasan: this.ringkasan,
        opd: this.opd,
        asal: this.asal,
      };
      this.$store.commit("documentIn/STATE", state);
      this.get(state);
      // this.$children[0].$refs.table.reload();
    },
    filterData: function () {
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        cari: this.cari,
        nomor_surat: this.nomor_surat,
        nomor_kendali: this.nomor_kendali,
        ringkasan: this.ringkasan,
        opd: this.opd,
        asal: this.asal,
      };
      this.$store.commit("documentIn/STATE", state);
      this.get(state);
    },
    doDetail(val) {
      this.$store.dispatch("documentIn/onDetail", val);
    },
    doDetailTL(val) {
      this.$store.dispatch("documentIn/onDetailTL", val);
    },
    doDetailTLI(val) {
      this.$store.dispatch("documentIn/onDetailTLI", val);
    },
    // doBerkas(val) {
    //   const data = {
    //     id: val.id,
    //     flag_atasan: 1,
    //   };
    //   // const data = JSON.stringify(payload);
    //   this.$store.dispatch("documentIn/submitTandaiAtasan", data);
    // },
    doAtasan(val) {
      const data = {
        id: val.id,
        flag_atasan: 1,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("documentIn/submitTandaiAtasanDepan", data);
    },
    doEdit(val) {
      this.$store.dispatch("documentIn/onEdit", val);
    },
    doKonfirmasi(val) {
      this.$store.dispatch("documentIn/onKonfirmasi", val);
    },
    // doKonfirmasi(val) {
    //   this.$store.dispatch("documentIn/onConfirm", val);
    // },
    doDisposisi(val) {
      this.$store.dispatch("documentIn/onDisposisi", val);
    },
    doDelete(val) {
      this.$store.dispatch("documentIn/submitDelete", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
        // loaded: false,
        // items: [],
        // from: this.from,
        // search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      if (this.from == "pencarian_khusus") {
        this.showFilter = !this.showFilter;
      }
      this.$store.commit("documentIn/STATE", state);
      this.get(state);
    },
  },
};
</script>
